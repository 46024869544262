/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

image {
  display: block;
  width: 100%;
}

.fullscreen {
  height: 100vh;
  width: 100vw;
}

.heading-underline {
  position: relative;
}
.heading-underline-accent {
  position: relative;
}

.heading-underline::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 75%;
  height: 3px;
  background-color: #4800ff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.heading-underline-accent::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 75%;
  height: 3px;
  background-color: #00d7c1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.about-img {
  -webkit-clip-path: polygon(0 0, 92% 0, 100% 100%, 8% 100%);
  clip-path: polygon(0 0, 92% 0, 100% 100%, 8% 100%);
}

.about-feature {
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.flipcard {
  min-height: 20rem;
  min-width: 20rem;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

@media screen and (max-width: 64em) {
  .flipcard {
    min-height: 15rem;
    min-width: 15rem;
  }
}

.flipcard-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition: -webkit-transform 0.6s ease-out;
  -o-transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out;
  transition: transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flipcard:hover .flipcard-inner {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.percent-bar {
  height: 2rem;
  width: 80%;
  /* border: 2px solid white; */
}

.flipped {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

/* .gradient-opacity {
  background-image: linear-gradient(
    to bottom right,
    transparent 25%,
    rgba(72, 0, 255, 0.02),
    rgba(0, 215, 193, 0.02),
    transparent 75%
  );
} */
.gradient-opacity {
  background: rgb(255, 255, 255);
  background: -o-radial-gradient(
    circle,
    rgba(72, 0, 255, 0.02) 0%,
    rgba(0, 215, 193, 0.02) 35%,
    transparent 65%,
    transparent 100%
  );
  background: radial-gradient(
    circle,
    rgba(72, 0, 255, 0.02) 0%,
    rgba(0, 215, 193, 0.02) 35%,
    transparent 65%,
    transparent 100%
  );
}

.image-container {
  position: relative;
  width: 27.2rem;
  height: 20.4rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 0.3 ease;
}

.animation-1 {
  animation: fadeIn1 1s ease-in-out;
}
.animation-2 {
  animation: fadeIn2 1.3s ease-in-out;
}
.animation-3 {
  animation: fadeIn3 1.6s ease-in-out;
}
.animation-4 {
  animation: fadeIn4 1.6s ease-in-out;
}
.animation-5 {
  animation: fadeIn5 1.3s ease-in-out;
}
.animation-6 {
  animation: fadeIn6 1s ease-in-out;
}

@keyframes fadeIn1 {
  from {
    opacity: 0;
    transform: translate(-100%, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes fadeIn2 {
  from {
    opacity: 0;
    transform: translate(0, -100%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes fadeIn3 {
  from {
    opacity: 0;
    transform: translate(100%, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes fadeIn4 {
  from {
    opacity: 0;
    transform: translate(-100%, 20%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes fadeIn5 {
  from {
    opacity: 0;
    transform: translate(0, 100%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
@keyframes fadeIn6 {
  from {
    opacity: 0;
    transform: translate(100%, 20%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.image-container::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: -1;
}

@media screen and (max-width: 64em) {
  .image-container {
    width: 32rem;
    height: 24rem;
  }
}
@media screen and (max-width: 40em) {
  .image-container {
    width: 22.4rem;
    height: 16.8rem;
  }
}

.image-container img {
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-filter 0.3s ease;
  transition: -webkit-filter 0.3s ease;
  -o-transition: filter 0.3s ease;
  transition: filter 0.3s ease;
  transition: filter 0.3s ease, -webkit-filter 0.3s ease;
}

.image-container:hover img {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  opacity: 0;
  -webkit-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
  transition: opacity 0.8s ease;
  background: rgba(255, 255, 255, 1); /* Semi-transparent overlay */
  overflow: hidden;
}

.image-container:hover .overlay {
  opacity: 1;
}

.overlay button {
  margin-top: 10px;
}

/* Name element - initially positioned above */
.overlay div {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%); /* Start from above */
  opacity: 0;
}

/* Button element - initially positioned below */
.overlay button {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%); /* Start from below */
  opacity: 0;
}

/* On hover, move both elements to their natural position */
.image-container:hover .overlay div,
.image-container:hover .overlay button {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.project-button {
  display: inline-block;
  border: 1px solid black;
  padding: 1rem 2rem;
  color: #ff00d4;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.project-button:hover {
  border: 1px solid #ff00d4;
  color: #fff;
  background-color: #ff00d4;
}

.transition-1s {
  -webkit-transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}

.contact-polygon {
  -webkit-clip-path: polygon(50% 8%, 100% 0, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(50% 8%, 100% 0, 100% 100%, 0 100%, 0 0);
}

@media screen and (max-width: 64em) {
  .contact-polygon {
    -webkit-clip-path: polygon(50% 6%, 100% 0, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(50% 6%, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@media screen and (max-width: 40em) {
  .contact-polygon {
    -webkit-clip-path: polygon(50% 4%, 100% 0, 100% 100%, 0 100%, 0 0);
    clip-path: polygon(50% 4%, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.heading-underline-base {
  position: relative;
}

.heading-underline-base::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  width: 75%;
  height: 3px;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modalbox {
  max-width: 65vw;
  max-height: 80vh;
  overflow-y: scroll;
}

.modalButtons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-left {
  width: 100%;
}

.btn-right {
  width: 100%;
}

@media screen and (max-width: 64em) {
  .modalbox {
    max-width: 85vw;
    max-height: 90vh;
  }
}
@media screen and (max-width: 50em) {
  .modalbox {
    max-width: 90vw;
    max-height: 100vh;
  }
}
@media screen and (max-width: 40em) {
  .modalbox {
    max-width: 95vw;
  }
}
@media screen and (max-width: 32em) {
  .modalbox {
    max-width: 95vw;
  }
}

.overlay-drop {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
}

.preload {
  display: none;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.header-gradient {
  background: linear-gradient(
    45deg,
    rgba(255, 0, 212, 1),
    rgba(72, 0, 255, 1),
    rgba(0, 215, 193, 1)
  );
  animation: gradientAnimation 20s ease infinite;
  background-size: 400% 400%;
  width: 100%;
  height: 100vh;
  position: relative;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
  top: 15% !important;
  right: 20px !important;
}

.transition-2s {
  transition-duration: 2000ms;
}
